// Options.js

// Options for designing
export const designedByOptions = [
  { value: 'inHouse', label: 'In-house', title: '自社' },
  { value: 'outsourcing', label: 'Outsourcing', title: '他社' }
];

// Data for models
export const modelData = [
  { name: "MODEL A", title: "モデルA", model_id: 1 },
  { name: "MODEL B", title: "モデルB", model_id: 2 },
  { name: "MODEL C", title: "モデルC", model_id: 3 },
  { name: "MODEL D", title: "モデルD", model_id: 4 }
];

// Options for polygon analysis
export const polygonOptions = [
  { value: 'completeScopeOfWork', label: 'Complete Scope of Work', title: '事業範囲', colour: 'blue' },
  { value: 'extentOfLandAlteration', label: 'Extent of Land Alteration', title: '土地変化範囲' , colour: ' #F67709' },
];

// Status options
export const statusOptions = [
  { value: 'inOperation', label: 'In-Operation', title: '営業中/仕掛中' },
  { value: 'lostOrder', label: 'Lost order', title: '失注' },
  { value: 'receivedDesigned', label: 'Received/Designed', title: '受注・設計中' },
  { value: 'constructionStarted', label: 'Construction started', title: '着工' },
  { value: 'completed', label: 'Completed', title: '完工' },
  { value: 'operation', label: 'Operation', title: '運用中' }
];
