import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ja';
import 'dayjs/locale/en';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

const locales = ['en', 'en-gb', 'ja', 'de'];

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    "& .MuiOutlinedInput-root": {
        padding: theme.spacing(0.5),
        fontSize: "0.875rem",
        "& fieldset": {
            borderColor: theme.palette.grey[300],
        },
        "&:hover fieldset": {
            borderColor: theme.palette.primary.main,
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.primary.main,
        },
        "&.Mui-error fieldset": {  
            borderColor: 'transparent',  
        },
    },
    "& .MuiFormLabel-root": {
        color: theme.palette.text.primary, 
        "&.Mui-error": {
            color: theme.palette.text.primary, 
        }
    },
    marginBottom: theme.spacing(1),
}));

const DateSelector = ({ 
    label, 
    value, 
    name, 
    handleChange, 
    minDate, 
    maxDate,
    disabled 
}) => {
    const { i18n, t } = useTranslation();
    const language = i18n.language;
    const dateFormat = language === 'en' ? 'YYYY-DD-MM' : 'YYYY-MM-DD';

    const handleClear = () => {
        handleChange({ target: { name, value: "" } });
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language === 'en' ? locales[0] : locales[2]}>
            <StyledDatePicker
                label={label}
                id={label}
                value={value ? dayjs(value) : null}
                name={name}
                onChange={(newValue) => handleChange({ target: { name, value: newValue ? newValue.format("YYYY-MM-DD") : "" } })} 
                minDate={minDate ? dayjs(minDate) : null}
                maxDate={maxDate ? dayjs(maxDate) : null}
                disabled={disabled}
                sx={{ width: "100%" }}
                slotProps={{
                    textField: {
                        readOnly: true,
                        inputProps: { placeholder: "yyyy-mm-dd" }
                    },
                    actionBar: {
                        actions: ["clear"],
                        sx: { display: "flex", justifyContent: "flex-end", padding: "8px" },
                        
                    }
                }}
                slots={{
                    actionBar: () => (
                        <Button 
                            onClick={handleClear} 
                            size="small" 
                            color="primary" 
                            sx={{ 
                                position: 'absolute',
                                bottom: 5, 
                                right: 5 
                            }}
                        >
                            {t("app.clear")}
                        </Button>
                    )
                }}
                format={dateFormat} 
            />
        </LocalizationProvider>
    );
};

export default DateSelector;
