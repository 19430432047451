import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeContextProvider } from './ThemeContext';
import globalStyles from './GlobalStyles';
import routes from './routes';
import { BasemapProvider } from './BaseMapProvider';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { getUser } from './api/user';
import { Hub } from "aws-amplify/utils";
import { getCurrentUser, fetchAuthSession } from "aws-amplify/auth";
import UnauthorizedAccess from './components/UnAuthorizedAccess';
import { useDispatch, useSelector } from 'react-redux';
import { initializeSocket } from './redux/socketSlice';
import { socket } from "./redux/socketSlice"; // Import socket directly
import { 
  setVerificationStatus, 
  setAdminStatus, 
  setUserData ,
  setUserId, 
  setToken
} from './redux/authSlice'; 
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import "./App.css";
import { useTranslation } from 'react-i18next';
import Error500Page from './pages/Error500Page';
import ServerErrorToast from './components/ServerErrorToast';

I18n.putVocabularies(translations);

function App() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [isVerified, setIsVerified] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("ja");
  const { t } = useTranslation();


  
  const checkUserVerification = useCallback(async () => {
    try {
        const user = await getCurrentUser();
        const userData = await getUser(user?.username);
        
        const isVerified = userData?.data?.UserAttributes?.find(attr => attr.Name === "custom:isVerified")?.Value === "true";
        const isAdmin = userData?.data?.UserAttributes?.find(attr => attr.Name === "custom:isAdmin")?.Value === "true";

        setIsVerified(isVerified);

        const session = await fetchAuthSession();
        
        dispatch(setToken(session?.tokens?.accessToken?.toString()));

        dispatch(setVerificationStatus(isVerified));
        dispatch(setAdminStatus(isAdmin));
        dispatch(setUserId(userData?.data?.Username));
        dispatch(setUserData(userData.data.UserAttributes)); 
    } catch (error) {
      if ( 
        error.code === "ERR_CONNECTION_REFUSED" || 
        error.code === "ERR_NETWORK" ||
        error.message.includes('Network Error')
      ) 
      {
        setHasServerError(true);
      } else {
        dispatch(setVerificationStatus(false));
        dispatch(setAdminStatus(false));
      }
      throw error;
    } finally {
        setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    // Initialize the socket connection when the app mounts
    dispatch(initializeSocket());

    // Optional: Clean up when the app unmounts
    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  useEffect(() => {
    I18n.putVocabularies({
      en: {
        "Preferred Username": "Preferred Username",
        "Enter your Preferred Username": "Enter your Preferred Username",
        "Password must have at least 8 characters": "Password must have at least 8 characters",
        "Password must have lower case letters": "Password must have lower case letters",
        "Password must have upper case letters": "Password must have upper case letters",
        "Password must have numbers": "Password must have numbers",
        "Your passwords must match": "Your passwords must match",
      },
      ja: {
        "Preferred Username": "優先ユーザ名",
        "Enter your Preferred Username": "お好みのユーザー名を入力してください",
        "Password must have at least 8 characters": "パスワードは8文字以上でなければなりません",
        "Password must have lower case letters": "パスワードには小文字を含める必要があります",
        "Password must have upper case letters": "パスワードには大文字を含める必要があります",
        "Password must have numbers": "パスワードには数字を含める必要があります",
        "Your passwords must match": "パスワードが一致する必要があります",
      },
    });
  
    I18n.setLanguage(currentLanguage);
  }, [currentLanguage]);
  

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const langParam = params.get("lang");
    if (langParam && (langParam === "en" || langParam === "ja")) {
      setCurrentLanguage(langParam);
    }
  }, []);

  useEffect(() => {
    const authListener = ({ payload }) => {
      switch (payload.event) {
        case "signedIn":
          checkUserVerification();
          break;
        case "signedOut":
          break;
        default:
          break;
      }
    };

    const hubListenerCancel = Hub.listen("auth", authListener);
    checkUserVerification();

    return () => {
      hubListenerCancel();
    };
  }, [checkUserVerification]);

  if (loading) {
    return <div>{t("app.loading")}...</div>; 
  }

  if (hasServerError) {
    return <Error500Page hasLogout={true}/>;
  }

  return (
    <ThemeContextProvider>
      <BasemapProvider>
        <CssBaseline />
        {globalStyles}
        {/* shows toast when server error occur */}
        <ServerErrorToast />

        <Authenticator
          
          initialState="signIn"
          loginMechanisms={["email"]}
          signUpAttributes={["email", "preferred_username"]}
          // i18nIsDynamicList = {true}
          passwordSettings={{
            minLength: 8,
            requireLowercase: true,
            requireUppercase: true,
            requireNumbers: true,
            requireSpecialCharacters: false,
          }}
        >
          {({ signOut, user }) => (
            <Router>
              <Routes>
                {user && isVerified ? (
                  routes(isAdmin).map((route, index) => (
                    <Route
                      key={index}
                      path={route.path}
                      element={route.element}
                    />
                  ))
                ) : (
                  [
                    <Route key="unauth" path="/" element={<UnauthorizedAccess />} />,
                  ]
                )}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
            </Router>
          )}
        </Authenticator>
      </BasemapProvider>
    </ThemeContextProvider>
  );
}

export default App;
